import React from 'react';

import { Typography } from '@mui/material';

import packageInfo from '../../package.json';

export type VersionProps = {
  value?: string;
};

const Version: React.FC<VersionProps> = ({ value }) => {
  return (
    <Typography variant="caption">
      {value || `v${packageInfo.version}`}
    </Typography>
  );
};

export default Version;

import React, { useEffect, useRef, useState } from 'react';

import {
  Box,
  Fade,
  Popper,
  styled,
  TextField,
  ThemeProvider,
} from '@mui/material';

import { RichMenuData, RichMenuItem } from '../types/commonTypes';
import { createUserToken } from '../utils/restApiUtil';

const RichMenuContainer = styled('div')`
  position: absolute;
  align-items: stretch;
  left: 0;
  bottom: calc(100% - var(--richmenu-height));
  width: 100%;
  min-height: 180px;
  max-height: none;
  z-index: 100;
  transition: 0.1s ease bottom;
  background: #e5f6ff;
  font-size: 16px;
  min-height: 218px;

  p {
    margin: 0;
  }

  &.active {
    bottom: 100%;
    transition: 0.1s ease bottom;
  }

  &.exist {
    display: flex;
  }

  &.empty {
    display: none;
  }

  .richMenuToggle {
    position: absolute;
    display: flex;
    justify-content: center;
    align-content: center;
    bottom: 100%;
    left: 16px;
    height: 32px;
    padding: 0 16px;
    background: #254b89;
    color: #fff;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid var(--border1-color);
    transition: background 0.1s ease;
    cursor: pointer;
    box-shadow: 0px -1px 3px 1px rgba(0, 0, 0, 0.4);

    &:hover {
      background: #3663ab;
    }
  }
  .richMenuInner:empty + .richMenuToggle {
    display: none;
  }

  .richMenuBtnText {
    display: block;
    height: 32px;
    line-height: 32px;
    font-weight: bold;
    font-size: 0.8em;
    border-radius: 4px 4px 0 0;
  }

  .hasRichMenu
    ~ [class*='RoomLayout__main__']
    [class*='presence-log__presence-log-in-room__'] {
    bottom: calc(var(--richmenu-toggle-height) + 8px);
  }

  .richMenuInner {
    overflow-y: hidden;
    overflow-x: scroll;
    display: flex;
    align-items: stretch;
    width: 100%;
    padding: 20px;
    /* pointer-events: none; */
  }

  .richMenuContainer.active .richMenuInner {
    opacity: 1;
    pointer-events: all;
  }

  .linkElems {
    overflow: hidden;
    position: relative;
    display: inline-block;
    max-width: 150px;
    min-width: 150px;
    max-height: 100%;
    margin-right: 15px;
    border-radius: 4px;
    box-shadow: 1px 1px 5px #aaa;
    background: #fff;
    filter: brightness(1);
    transition: filter 0.1s ease;

    &:hover {
      filter: brightness(1.2);
    }

    &.--hide {
      display: none;
    }
  }

  .linkZone {
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    text-decoration: none;
  }

  .linkThumbnail {
    display: block;
    min-width: 100%;
    height: 80px;
    object-fit: cover;
  }

  .linkText {
    padding: 10px 10px 48px 10px;
    text-align: left;
    word-break: break-all;
    line-height: 1.25;
    color: #007ab8;
  }

  .linkAction {
    position: absolute;
    width: 100%;
    bottom: 10px;
    left: 0;
  }

  .moveButton {
    display: inline-block;
    max-width: 100%;
    margin: auto;
    padding: 7px 25px;
    background: #12a4ed;
    color: #fff;
    border-radius: 4px;
    text-align: center;
    font-size: 0.8em;
    font-weight: bold;
    line-height: 1.15;
  }

  .roomCount {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 48px;
    padding: 4px 10px 4px 4px;
    border-radius: 40px;
    background: #cfcfcf;
    text-align: right;
    color: #000;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 8px;
      transform: translate(0, -50%);
      width: 16px;
      height: 16px;
      background: url(https://cyzyspace-public-assets.s3.amazonaws.com/extra_scripts/img/roomCount.svg)
        center (center / contain) no-repeat;
    }
  }

  @media (min-width: 992px) {
    .richMenuToggle {
      left: 24px;
      padding: 0 30px;
    }

    .richMenuBtnText {
      font-size: 0.9rem;
    }
  }
`;

export type RichMenuProps = {
  data?: RichMenuData;
};

//@ts-ignore
const RichMenu = ({ data }) => {
  const [active, setActive] = useState(false);
  const [richMenuHeight, setRichMenuHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (ref.current) {
      const clientHeight = ref?.current.clientHeight;
      setRichMenuHeight(clientHeight);
    }
  });

  useEffect(() => {
    document.documentElement.style.setProperty(
      '--richmenu-height',
      `${richMenuHeight}px`
    );
  }, [richMenuHeight]);

  const handleToggleActive = () => {
    setActive(!active);
  };
  const handleLinkClick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    const target = e.currentTarget.target;
    const baseUrl = e.currentTarget.href;
    e.preventDefault();
    if (!baseUrl) {
      return;
    }
    const userToken: string | null | undefined = await createUserToken(null);
    const url = new URL(baseUrl);
    url.searchParams.append('cyzyUserToken', userToken || '');
    // e.currentTarget.href = url.toString();
    if (target && target === '_blank') {
      const newWindow = window.open(url.toString(), '_blank');
      if (newWindow) {
        newWindow.focus();
      } else {
        // console.warn('New window was blocked or failed to open.');
      }
    } else {
      window.location.href = url.toString();
    }
  };

  return (
    <>
      <RichMenuContainer
        id="richMenuContainer"
        className={`richMenuContainer ${active && 'active'}`}
        ref={ref}
      >
        <div id="richMenuInner" className="richMenuInner">
          {data.items &&
            data.items.map(
              (item: RichMenuItem, i: number) =>
                item.show &&
                item.url && (
                  <div className={`linkElems`} key={i}>
                    <a
                      className="linkZone"
                      href={item.url || ''}
                      target={item.openNewWindow ? '_blank' : '_self'}
                      rel="noreferrer"
                      onClick={handleLinkClick}
                    >
                      <img
                        className="linkThumbnail"
                        src={item.thumbnail}
                        alt={item.title}
                      />
                      <p className="linkText">
                        {item.title ? item.title : 'リンク'}
                      </p>
                      <div className="linkAction">
                        <div className="moveButton">移動する</div>
                      </div>
                    </a>
                  </div>
                )
            )}
        </div>
        <div
          id="richMenuToggle"
          className="richMenuToggle"
          onClick={handleToggleActive}
        >
          <div id="richMenuBtnText" className="richMenuBtnText">
            {data.richMenuBtnText !== '' ? data.richMenuBtnText : 'メニュー'}
          </div>
        </div>
      </RichMenuContainer>
    </>
  );
};

export default RichMenu;

import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const StyledPre = styled('pre')`
  background-color: #f0f0f0;
  font-size: 8px;
  word-break: break-all;
  white-space: pre-wrap;
`;

type Props = {
  children: string;
  title?: string;
};

const { REACT_APP_ENABLE_DEBUGLOG } = process.env;

export default function DebugLog({ children, title }: Props) {
  return REACT_APP_ENABLE_DEBUGLOG && REACT_APP_ENABLE_DEBUGLOG === '1' ? (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="caption">{`[Debug Log] ${
          title || ''
        }`}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ width: '95%' }} ml={1} mt={1}>
          <StyledPre>{children}</StyledPre>
        </Box>
      </AccordionDetails>
    </Accordion>
  ) : null;
}

import { forwardRef, KeyboardEvent, useState } from 'react';

import SendIcon from '@mui/icons-material/Send';
import {
  Box,
  Fade,
  IconButton,
  InputAdornment,
  Popper,
  styled,
  TextField,
} from '@mui/material';

const ChatWrapper = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 320px;
  height: calc(100svh - 345px);
  ${[theme.breakpoints.up('md')]} {
    height: calc(100svh - 200px);
  }
  margin-bottom: 8px;
  border-radius: 8px;
  background: linear-gradient(0deg, rgb(32, 103, 136) 0%, rgb(77, 193, 166) 100%);
`
);
const ChatIFrame = styled('iframe')`
  flex-grow: 1;
  border: 0;
  width: 100%;
  border-radius: 6px 6px 0 0;
`;

const StyledTextField = styled(TextField)`
  border: none;
  padding: 8px;
  .MuiInputBase-root {
    padding: 8px 12px;
    background-color: rgba(0, 0, 0, 0.5);
  }
  textarea {
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    font-weight: bold;
  }
`;

export type ChatWindowProps = {
  open: boolean;
  id: string;
  anchorEl: HTMLElement | null;
  userName: string;
  roomId: string;
  playerId: string;
  onMessageSubmit: (message: string) => void;
};

const ChatWindow = forwardRef<HTMLIFrameElement, ChatWindowProps>(
  (
    { open, id, anchorEl, roomId, userName, playerId, onMessageSubmit },
    ref
  ) => {
    const [message, setMessage] = useState('');
    const [sent, setSent] = useState(false);

    const handleMessageChange = (
      event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
      if (sent) {
        setSent(false);
        setMessage('');
      } else {
        setMessage(event.currentTarget.value);
      }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
      if (
        (event.code === 'Enter' || event.key === 'Enter') &&
        event.code !== 'NumpadEnter' &&
        event.shiftKey === false &&
        !event.nativeEvent
          .isComposing /*変換中のEnterを除外するための暫定処置。ただしSafariでは効かない */ &&
        message.length > 0
      ) {
        handleSubmit();
      }
    };

    const handleSubmit = () => {
      // event.preventDefault();
      onMessageSubmit && onMessageSubmit(message);
      setMessage('');
      setSent(true);
    };

    return (
      <>
        <Popper
          id={id}
          open={open}
          anchorEl={null}
          keepMounted={true}
          transition
          placement="top-start"
          sx={{
            zIndex: '101',
            bottom: { xs: '270px', sm: '270px', md: '120px' },
            right: '16px',
            left: 'auto !important',
            top: 'auto !important',
          }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <ChatWrapper>
                <ChatIFrame
                  ref={ref}
                  title="chat-component"
                  src={`${process.env.REACT_APP_CHAT_SERVICE_URL}/room?roomId=${roomId}&userName=${userName}&playerId=${playerId}&hideInput=1`}
                  onClick={() => {
                    console.log('iframe onClick');
                  }}
                ></ChatIFrame>
                <StyledTextField
                  size="small"
                  fullWidth
                  multiline
                  maxRows={5}
                  placeholder="メッセージを入力"
                  value={message}
                  onChange={handleMessageChange}
                  onKeyDown={handleKeyDown}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleSubmit}
                          disabled={!message}
                          sx={{
                            color: '#FFF',
                            '&.Mui-disabled': { color: '#666' },
                          }}
                        >
                          <SendIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </ChatWrapper>
            </Fade>
          )}
        </Popper>
      </>
    );
  }
);

export default ChatWindow;

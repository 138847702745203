import axios from 'axios';
import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { useAccount } from '../contexts/AccountContext';
import useAvatars from '../hooks/useAvatars';
import { Avatar } from '../types/prisma';
import AvatarListBox from './AvatarListBox';
import DebugLog from './DebugLog';

const { REACT_APP_API_SERVER_URL } = process.env;

export type ProfileDialogProps = {
  open: boolean;
  onCancel?: () => void;
  onSubmit?: (avatarName: string) => void;
  id?: string;
};

const ProfileDialog: React.FC<ProfileDialogProps> = ({
  open,
  id,
  onCancel,
  onSubmit,
}) => {
  const avatars = useAvatars();
  const account = useAccount();

  const [name, setName] = useState('');
  const [avatarId, setAvatarId] = useState('');
  const [avatarName, setAvatarName] = useState('');

  useEffect(() => {
    setName(account.player?.name || '');
    setAvatarId(account.player?.avatarId || '');
    setAvatarName(account.player?.avatarName || '');
  }, [account]);

  const handleNameChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setName(event.target.value);
  };

  const handleAvatarClick = (avatar: Avatar) => {
    setAvatarId(avatar.id);
    setAvatarName(avatar.assetName);
  };

  const handleCancel = () => {
    onCancel && onCancel();
  };

  const handleSubmit = async () => {
    try {
      const params = {
        roomId: account.room?.id,
        playerId: account.player?.id,
        name,
        avatarId,
      };
      console.log(params);
      const result = await axios.patch(
        `${REACT_APP_API_SERVER_URL}/api/players/`,
        params
      );
      console.log(result);
    } catch (error) {
      console.error('PATCH /api/players', error);
    }

    onSubmit && onSubmit(avatarName);
  };

  return (
    <Dialog open={open} id={id} onClose={handleCancel}>
      <DialogTitle>名前/アバター変更</DialogTitle>
      <DialogContent>
        <Stack spacing={1} margin={1}>
          <Box>
            <TextField
              fullWidth
              value={name}
              label="名前"
              onChange={handleNameChange}
            />
          </Box>
          <Box>
            <Typography>アバター</Typography>
          </Box>
          <Box>
            {!avatars && <CircularProgress />}
            <AvatarListBox
              data={avatars}
              currentAvatarId={avatarId}
              onClick={handleAvatarClick}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleCancel}>
          キャンセル
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!name}
        >
          更新する
        </Button>
      </DialogActions>
      <DebugLog title="avatars">{JSON.stringify(avatars, null, 2)}</DebugLog>
      <DebugLog title="account">{JSON.stringify(account, null, 2)}</DebugLog>
    </Dialog>
  );
};

export default ProfileDialog;

import React from 'react';

import PanToolIcon from '@mui/icons-material/PanTool';
import {
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';

import { RoomMember } from '../utils/restApiUtil';

type GuestViewDialogProps = {
  openDialog: string | null;
  handleDialogClose: () => void;
  roomId: string;
  playerCount: number;
  playerList: RoomMember[] | null;
};
const GuestViewDialog: React.FC<GuestViewDialogProps> = ({
  openDialog,
  handleDialogClose,
  playerList,
}) => {
  return (
    <Dialog
      onClose={handleDialogClose}
      open={openDialog === 'guestView'}
      id="guestView"
    >
      <DialogTitle>参加者一覧</DialogTitle>
      <DialogContent sx={{ minWidth: '320px', maxWidth: '90svw' }}>
        <List sx={{ pt: 0 }}>
          {playerList &&
            playerList.map((player, i) => (
              <ListItem key={i} disableGutters>
                <ListItemAvatar>
                  <Avatar>{player.name.slice(0, 1)}</Avatar>
                </ListItemAvatar>
                <ListItemText>{player.name}</ListItemText>
                {player.status > 0 && (
                  <IconButton edge="end">
                    <PanToolIcon sx={{ color: '#e6860a' }} />
                  </IconButton>
                )}
              </ListItem>
            ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default GuestViewDialog;

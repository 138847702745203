import axios from 'axios';

import { Avatar, Player } from '../types/prisma';
import {
  getPlayerIdFromLocalStorage,
  setTokenToLocalStorage,
} from './localStorageUtil';

const {
  REACT_APP_API_SERVER_URL,
  REACT_APP_DEFAULT_AVATAR_ID,
  REACT_APP_CYZY_USER_URL,
} = process.env;

export const getPlayer = async (playerId: string | null | undefined) => {
  if (!playerId) {
    return null;
  }
  try {
    const result = await axios.get(
      `${REACT_APP_API_SERVER_URL}/api/players/${playerId}`
    );
    const player = result.data as Player;
    if (!player.id) {
      return null;
    }
    return player;
  } catch (error) {
    console.error('GET /players/:id', error);
    return null;
  }
};
export const createUserToken = async (playerId: string | null | undefined) => {
  if (!playerId) {
    playerId = getPlayerIdFromLocalStorage();
    if (!playerId) {
      return null;
    }
  }
  try {
    const result = await axios.get(
      `${REACT_APP_API_SERVER_URL}/api/players/${playerId}`
    );
    const player = result.data;
    if (!player.id || !player?.name || !player?.avatar_displayName) {
      return null;
    }
    //CreateTokenFromRecord
    const body: object | null = {
      name: player?.name,
      daisyAvatarName: player?.avatar_displayName,
    };
    try {
      const tokenResult = await axios.post(
        `${REACT_APP_CYZY_USER_URL}/users`,
        body
      );
      return tokenResult?.data?.token;
    } catch (error) {
      console.error('POST /users', error);
      return null;
    }
  } catch (error) {
    console.error('GET /players/:id', error);
    return null;
  }
};

export const getOrCreatePlayer = async () => {
  let player: Player | null = null;

  // プレイヤーIDをLocalStorageから取得
  const playerId = getPlayerIdFromLocalStorage();
  if (playerId) {
    // プレイヤー情報を取得する
    player = await getPlayer(`${playerId}`);
  }

  // 見つからない、もしくは無効であれば新規発行
  if (!player) {
    try {
      const result = await axios.post(
        `${REACT_APP_API_SERVER_URL}/api/players`,
        {
          name: `GUEST_${
            Math.floor(Math.random() * (99999 + 1 - 10000)) + 10000
          }`,
          avatarId: REACT_APP_DEFAULT_AVATAR_ID,
        }
      );
      player = result.data;
      const token = result.data.token;
      setTokenToLocalStorage(token);
    } catch (error) {
      console.error('POST /api/players', error);
    }
  }

  //V2:Token情報のPlayer反映
  const cyzyUserToken = new URLSearchParams(window.location.search).get(
    'cyzyUserToken'
  );
  if (cyzyUserToken) {
    console.log('Check Cyzy User Token');
    let tokenAvatarId: string | null = null;
    let tokenUserName: string | null = null;
    //GetDataFromToken
    try {
      const tokenData = await axios.get(
        `${REACT_APP_CYZY_USER_URL}/users?token=${cyzyUserToken}`
      );
      if (tokenData?.data?.daisyAvatarName) {
        const tokenAvatarData = await axios.get(
          `${REACT_APP_API_SERVER_URL}/api/avatars/name/${tokenData?.data?.daisyAvatarName}`
        );
        if (tokenAvatarData?.data?.id) {
          tokenAvatarId = tokenAvatarData?.data?.id;
        }
      }
      if (tokenData?.data?.name) {
        tokenUserName = tokenData?.data?.name;
      }
    } catch (error) {
      console.error('GET /api/token', error);
    }
    //CompareTokenWithPlayer
    if (tokenAvatarId || tokenUserName) {
      console.log('User Detected');
      try {
        const params = {
          playerId: player?.id,
          name: tokenUserName ? tokenUserName : player?.name,
          avatarId: tokenAvatarId ? tokenAvatarId : player?.avatarId,
        };
        const tokenResult = await axios.patch(
          `${REACT_APP_API_SERVER_URL}/api/players/`,
          params
        );
        player = tokenResult.data;
      } catch (error) {
        console.error('PATCH /api/players', error);
      }
    }
  }

  // Unity側処理更新までの移行処置
  if (playerId) {
    localStorage.setItem('PlayerId', playerId);
  }

  return player;
};

export type RoomMember = {
  status: number;
  id: string;
  name: string;
};

export const getRoomMembers = async (roomId: string): Promise<RoomMember[]> => {
  if (!roomId) {
    return [];
  }

  try {
    const result = await axios.get(
      `${REACT_APP_API_SERVER_URL}/api/rooms/${roomId}/members`
    );

    return result.data as RoomMember[];
  } catch (error) {
    console.error('getRoomMembers', error);
    return [];
  }
};

import React, { useEffect, useRef, useState } from 'react';

import { styled } from '@mui/material/styles';

import Hls from 'hls.js';

type PopupStreamViewDialogProps = {
  open: boolean;
  url: string | undefined;
  onCancel: () => void;
};
//styled
const PopupModal = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.25)',
  zIndex: 200,
});

const PopupWindowWrap = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '90%',
  border: '4px solid #777',
  background: '#fff',
  transition: 'all 0.2s ease',
});

const PopupWindow = styled('iframe')({
  width: '100%',
  height: '100%',
  border: 'none',
});

const PopupWindowClose = styled('div')({
  width: '36px',
  height: '36px',
  position: 'absolute',
  top: '-1px',
  right: '-1px',
  background:
    '#777 url(https://cyzyspace-public-assets.s3.amazonaws.com/extra_scripts/img/close.svg) center center / 70% 70% no-repeat',
  cursor: 'pointer',
});

const StyledVideo = styled('video')({
  width: '100%',
  height: '100%',
  border: 'none',
});

const PopupStreamViewDialog: React.FC<PopupStreamViewDialogProps> = ({
  open,
  url,
  onCancel,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isStream, setIsStream] = useState<boolean>(false);

  useEffect(() => {
    const initializeHls = () => {
      if (videoRef.current && url) {
        const hls = new Hls();
        hls.loadSource(url);
        hls.attachMedia(videoRef.current);
        return () => {
          hls.removeAllListeners();
          hls.stopLoad();
        };
      }
    };

    if (url) {
      const url_ = new URL(url);
      // setIsStream(url_.pathname.includes('.m3u8'));
      if (!videoRef.current) {
        videoRef.current = document.createElement('video');
      }
      if (url_.pathname.includes('.m3u8')) {
        setIsStream(true);
        initializeHls();
      } else {
        setIsStream(false);
      }
    }
  }, [url]);

  const handleCancel = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
    onCancel && onCancel();
  };

  return (
    <PopupModal style={{ display: open ? 'block' : 'none' }}>
      <PopupWindowWrap>
        {/* 下記commentOutが正常に動作しないため親でm3u8限定にする */}
        {/* {isStream ? (
          <StyledVideo ref={videoRef} controls>
            <source src={url} type="application/x-mpegURL" />
            Your browser does not support the video tag.
          </StyledVideo>
        ) : (
          <PopupWindow
            style={{ background: '#FFF' }}
            src={url}
            title="Popup Viewer"
          />
        )} */}
        <StyledVideo ref={videoRef} controls>
          <source src={url} type="application/x-mpegURL" />
          Your browser does not support the video tag.
        </StyledVideo>
        <PopupWindowClose onClick={handleCancel} />
      </PopupWindowWrap>
    </PopupModal>
  );
};

export default PopupStreamViewDialog;

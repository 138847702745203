import axios from 'axios';
import { useEffect, useState } from 'react';

import { Avatar } from '../types/prisma';

const { REACT_APP_API_SERVER_URL } = process.env;

const useAvatars = () => {
  const [avatars, setAvatars] = useState<Avatar[]>([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const result = await axios.get(
          `${REACT_APP_API_SERVER_URL}/api/avatars`
        );
        const items: Avatar[] = result.data.items;
        setAvatars(items.filter((v) => v.displayName !== 'DummyHuman'));
      } catch (error) {
        console.error('GET /avatars', error);
        setAvatars([]);
      }
    }
    fetchData();
  }, []);

  return avatars;
};

export default useAvatars;

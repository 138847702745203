import axios from 'axios';
import { useEffect, useState } from 'react';

import { Room } from '../types/prisma';

const { REACT_APP_API_SERVER_URL } = process.env;

const useRoom = (roomId: string) => {
  const [room, setRoom] = useState<Room | null>(null);

  useEffect(() => {
    async function fetchData() {
      if (!roomId) {
        setRoom(null);
        return;
      }
      try {
        const result = await axios.get(
          `${REACT_APP_API_SERVER_URL}/api/rooms/${roomId}`
        );
        console.log(
          `${REACT_APP_API_SERVER_URL}/api/rooms/${roomId}`,
          result.data
        );
        setRoom(result.data);
      } catch (error) {
        console.error('GET /rooms/:id', error);
        setRoom(null);
      }
    }
    fetchData();
  }, [roomId]);

  return room;
};

export default useRoom;

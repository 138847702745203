import axios from 'axios';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import { Unity, useUnityContext } from 'react-unity-webgl';

import { Box, styled } from '@mui/material';

import { useAccount } from './contexts/AccountContext';

const { REACT_APP_API_SERVER_URL } = process.env;

const StyledUnity = styled(Unity)`
  width: 100%;
  height: calc(100% - 96px);
`;

type MainPageProps = {
  onLoaded?: (isLoaded: boolean, progression: number) => void;
  onUnityEnteringRoom?: (status: string) => void;
  onUnityUpdateEmote?: (data: string) => void;
  onUnityUpdatePlayerNum?: (count: number) => void;
  onUnitySocketStatus?: (data: string) => void;
  onUnityRedirect?: (data: string) => void;
  onUnityBotEnter?: (data: string) => void;
  onUnityBotExit?: (data: string) => void;
};

export type UnityConnectionParams = {
  url_api?: string;
  url_ws?: string;
  url_resource?: string;
  url_texture_source?: string;
  bot_polling_span?: string;
};

const MainPage = forwardRef(
  (
    {
      onUnityUpdateEmote,
      onUnityUpdatePlayerNum,
      onUnityEnteringRoom,
      onUnitySocketStatus,
      onUnityRedirect,
      onUnityBotEnter,
      onUnityBotExit,
      onLoaded,
    }: MainPageProps,
    ref
  ) => {
    const {
      unityProvider,
      sendMessage,
      addEventListener,
      removeEventListener,
      isLoaded,
      loadingProgression,
    } = useUnityContext({
      loaderUrl: '/UnityBuilds/webgl/Build/webgl.loader.js',
      dataUrl: '/UnityBuilds/webgl/Build/webgl.data.unityweb',
      frameworkUrl: '/UnityBuilds/webgl/Build/webgl.framework.js.unityweb',
      codeUrl: '/UnityBuilds/webgl/Build/webgl.wasm.unityweb',
      streamingAssetsUrl: '/UnityBuilds/webgl/StreamingAssets',
      webglContextAttributes: {
        powerPreference: 'high-performance',
        premultipliedAlpha: true,
      },
    });

    const account = useAccount();
    const playerId = account.player?.id;

    useImperativeHandle(ref, () => ({
      // このコンポーネント外からUnityを操作する関数
      disableUnityInput() {
        sendMessage('Src', 'ForceInputForWeb', 1);
      },
      enableUnityInput() {
        sendMessage('Src', 'ForceInputForWeb', 0);
      },
      updateUnityAvatar(assetName: string) {
        sendMessage('Src', 'ChangeAvatarFromWeb', assetName);
      },
      enableTpsCamera() {
        sendMessage('Src', 'isThirdPersonViewFromWeb', 3);
      },
      disableTpsCamera() {
        sendMessage('Src', 'isThirdPersonViewFromWeb', 1);
      },
      enablePostProcessing() {
        sendMessage('Src', 'SetPostProcessFromWeb', 1);
      },
      disablePostProcessing() {
        sendMessage('Src', 'SetPostProcessFromWeb', 0);
      },
      enableStreamMovieMute() {
        sendMessage('Src', 'SetStreamMovieMuted', 0);
      },
      disableStreamMovieMute() {
        sendMessage('Src', 'SetStreamMovieMuted', 1);
      },
      changeVideoVolume(value: number) {
        // default:1.0f , min:0.1f , max:1.0f
        sendMessage('Src', 'SetStreamMovieVolume', value);
      },
      changeCameraSpeed(value: number) {
        // default:1.0f , min:0.1f , max:1.0f
        sendMessage('Src', 'SetCamSpeedFromWeb', value);
      },
      changeMoveSpeed(value: number) {
        // default:4.0f , min:1.0f , max:5.0f
        sendMessage('Src', 'SetMoveSpeedFromWeb', value);
      },
      setConnection(value: UnityConnectionParams) {
        // value:json.stringifyed
        sendMessage('Src', 'ConnectionSettingFromWeb', JSON.stringify(value));
      },
      async updateEmote(
        roomId: string,
        emoteState: boolean,
        emoteStr: 'raiseHand' | 'somethingEmote'
      ) {
        const emoteCodeList = {
          raiseHand: 1,
          somethingEmote: 2,
        };
        const emoteId =
          emoteState && emoteCodeList[emoteStr] ? emoteCodeList[emoteStr] : 0;
        const postData = { emoteId, playerId, roomId };
        console.log({ roomId, emoteState, emoteStr, postData });
        const response = await axios.post(
          `${REACT_APP_API_SERVER_URL}/api/players/emotes`,
          postData
        );
        console.log(response.status);
      },
      enterRoom(playerId: string) {
        const paramsStr = JSON.stringify({
          PlayerId: playerId,
        });
        sendMessage('Src', 'EnterRoomFromWeb', paramsStr);
      },
    }));

    // リスナー登録
    useEffect(() => {
      const COMMAND = 'update_emote';
      const listener = (data: string) => {
        console.log(COMMAND, data);
        onUnityUpdateEmote && onUnityUpdateEmote(data);
      };
      addEventListener(COMMAND, listener);
      return () => {
        removeEventListener(COMMAND, listener);
      };
    }, [addEventListener, removeEventListener, onUnityUpdateEmote]);

    useEffect(() => {
      const COMMAND = 'update_player_num';
      const listener = (count: number) => {
        // console.log(COMMAND, count);
        onUnityUpdatePlayerNum && onUnityUpdatePlayerNum(count);
      };
      addEventListener(COMMAND, listener);
      return () => {
        removeEventListener(COMMAND, listener);
      };
    }, [addEventListener, removeEventListener, onUnityUpdatePlayerNum]);

    useEffect(() => {
      const COMMAND = 'interact_target';
      const listener = (data: unknown) => {
        console.log(COMMAND, data);
      };
      addEventListener(COMMAND, listener);
      return () => {
        removeEventListener(COMMAND, listener);
      };
    }, [addEventListener, removeEventListener]);

    useEffect(() => {
      const COMMAND = 'join_sequence';
      const listener = (status: string) => {
        console.log(COMMAND, status);
        onUnityEnteringRoom && onUnityEnteringRoom(status);
      };
      addEventListener(COMMAND, listener);
      return () => {
        removeEventListener(COMMAND, listener);
      };
    }, [addEventListener, removeEventListener, onUnityEnteringRoom]);

    useEffect(() => {
      const COMMAND = 'redirect_href';
      const listener = (data: string) => {
        console.log(COMMAND, data);
        onUnityRedirect && onUnityRedirect(data);
      };
      addEventListener(COMMAND, listener);
      return () => {
        removeEventListener(COMMAND, listener);
      };
    }, [addEventListener, removeEventListener, onUnityRedirect]);

    useEffect(() => {
      const COMMAND = 'onBotEnter';
      const listener = (data: string) => {
        // console.log(COMMAND, data);
        onUnityBotEnter && onUnityBotEnter(data);
      };
      addEventListener(COMMAND, listener);
      return () => {
        removeEventListener(COMMAND, listener);
      };
    }, [addEventListener, removeEventListener, onUnityBotEnter]);

    useEffect(() => {
      const COMMAND = 'onBotExit';
      const listener = (data: string) => {
        // console.log(COMMAND, data);
        onUnityBotExit && onUnityBotExit(data);
      };
      addEventListener(COMMAND, listener);
      return () => {
        removeEventListener(COMMAND, listener);
      };
    }, [addEventListener, removeEventListener, onUnityBotExit]);

    useEffect(() => {
      const COMMAND = 'ws_status';
      const listener = (data: string) => {
        console.log(COMMAND, data);
        onUnitySocketStatus && onUnitySocketStatus(data);
      };
      addEventListener(COMMAND, listener);
      return () => {
        removeEventListener(COMMAND, listener);
      };
    }, [addEventListener, removeEventListener, onUnitySocketStatus]);

    useEffect(() => {
      onLoaded && onLoaded(isLoaded, loadingProgression);
    }, [isLoaded, onLoaded, loadingProgression]);

    return (
      <Box
        sx={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100svw',
          height: '100svh',
        }}
      >
        <StyledUnity
          unityProvider={unityProvider}
          devicePixelRatio={window.devicePixelRatio}
          tabIndex={1}
        />
      </Box>
    );
  }
);

export default MainPage;

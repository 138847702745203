import React, { useEffect, useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { styled } from '@mui/material/styles';

const PopupModal = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
  background: 'rgba(0, 0, 0, 0.25)',
  zIndex: 200,
});

const PopupWindowWrap = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '90%',
  border: '4px solid #777',
  background: '#fff',
  transition: 'all 0.2s ease',
});

const PopupWindow = styled('iframe')({
  width: '100%',
  height: '100%',
  border: 'none',
});

const PopupWindowClose = styled('div')({
  width: '36px',
  height: '36px',
  position: 'absolute',
  top: '-1px',
  right: '-1px',
  background:
    '#777 url(https://cyzyspace-public-assets.s3.amazonaws.com/extra_scripts/img/close.svg) center center / 70% 70% no-repeat',
  cursor: 'pointer',
});

const PopupToggle = styled('div')({
  display: 'none',
  position: 'absolute',
  bottom: 'calc(100% + 12px)',
  right: '10px',
  padding: '14px 14px 14px 44px',
  zIndex: 0,
  borderRadius: '4px',
  fontSize: '12px',
  fontWeight: 'bold',
  background: '#244b89',
  color: '#fff',
  cursor: 'pointer',
  transition: 'background 0.1s ease',
  '&.show': {
    display: 'block',
  },
  '&:hover': {
    background: '#3663ab',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '4px',
    transform: 'translate(0, -50%)',
    display: 'block',
    width: '32px',
    height: '20px',
    borderRight: '1px solid #fff',
    background:
      'url(https://cyzyspace-public-assets.s3.amazonaws.com/extra_scripts/img/icon-clip.svg) center center / contain no-repeat',
  },
});

const RoomLayoutSidebar = styled('div')({
  zIndex: 1,
});

export type PopupViewDialogProps = {
  open: boolean;
  url: string | undefined;
  closeOnly?: boolean;
  onCancel?: () => void;
};

const PopupViewDialog: React.FC<PopupViewDialogProps> = ({
  open,
  url,
  onCancel,
}) => {
  const handleCancel = () => {
    onCancel && onCancel();
  };
  if (!open) {
    return null;
  }

  return (
    <PopupModal>
      <PopupWindowWrap>
        <PopupWindowClose onClick={handleCancel} />
        <PopupWindow
          style={{ background: '#FFF' }}
          src={url}
          title="Popup Viewer"
        />
      </PopupWindowWrap>
    </PopupModal>
  );
};

export default PopupViewDialog;

import jwt_decode from 'jwt-decode';

const TOKEN_KEY = 'daisy-player-token';

type JWT = {
  exp: number;
  sub: string;
};

export const getPlayerIdFromLocalStorage = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (token) {
    const jwt: JWT = jwt_decode(token);
    const now = Date.now() / 1000;
    if (jwt.exp >= now) {
      return jwt.sub;
    }
  }
  return null;
};

export const setTokenToLocalStorage = (token: string) => {
  localStorage.setItem(TOKEN_KEY, token);
};

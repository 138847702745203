import axios from 'axios';
import { useEffect, useState } from 'react';

import { RichMenuData } from '../types/commonTypes';

const { REACT_APP_RESOURCE_LINK_URL } = process.env;

const useRichMenu = (roomId: string) => {
  // const richMenuConfigUrl = `${REACT_APP_API_SERVER_URL}/${roomId}/menu-config.json`;
  const [richMenu, setRichMenu] = useState<RichMenuData | null>(null);

  useEffect(() => {
    async function fetchData() {
      if (!roomId) {
        setRichMenu(null);
        return;
      }
      try {
        const result = await axios.get(
          `${REACT_APP_RESOURCE_LINK_URL}/assets/${roomId}/menu-config.json`
        );
        console.log(
          `${REACT_APP_RESOURCE_LINK_URL}/assets/${roomId}/menu-config.json`,
          result.data
        );
        if (result.data.userRichMenuUrl && result.data.useUserRichMenu) {
          const userRichMenuResult = await axios.get(
            result.data.userRichMenuUrl
          );
          console.log(result.data.userRichMenuUrl, userRichMenuResult.data);
          setRichMenu(userRichMenuResult.data);
        } else {
          setRichMenu(result.data);
        }
      } catch (error) {
        console.error('GET richMenuConfigUrl', error);
        setRichMenu(null);
      }
    }
    fetchData();
  }, [roomId]);

  return richMenu;
};

export default useRichMenu;

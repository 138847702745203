import { useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Slider,
  styled,
  TextField,
  Typography,
} from '@mui/material';

export type SettingsDialogProps = {
  open: boolean;
  title?: string;
  confirmText?: string;
  closeOnly?: boolean;
  onSubmit?: (props: SettingProps) => void;
  onCancel?: () => void;
};

export type SettingProps = {
  videoVolume?: number;
  cameraSpeed?: number;
  moveSpeed?: number;
  enablePostProcess?: boolean;
};
const SettingItem = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  & + & {
    margin-top: 16px;
  }
`;
const SettingsDialog: React.FC<SettingsDialogProps> = ({
  open,
  title,
  closeOnly,
  confirmText,
  onSubmit,
  onCancel,
}) => {
  const [videoVolume, setVideoVolume] = useState<number>(100);
  const [cameraSpeed, setCameraSpeed] = useState<number>(50);
  const [moveSpeed, setMoveSpeed] = useState<number>(4);
  const [enablePostProcess, setEnablePostProcessChecked] = useState(true);
  const handleVideoVolumeChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    setVideoVolume(newValue as number);
  };
  const handleCameraSpeedChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    setCameraSpeed(newValue as number);
  };
  const handleMoveSpeedChange = (event: Event, newValue: number | number[]) => {
    setMoveSpeed(newValue as number);
  };
  const handleEnablePostProcessChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEnablePostProcessChecked(event.target.checked);
  };
  const handleCancel = () => {
    onCancel && onCancel();
  };
  const handleSubmit = () => {
    const props: SettingProps = {
      videoVolume: videoVolume,
      cameraSpeed: cameraSpeed,
      moveSpeed: moveSpeed,
      enablePostProcess: enablePostProcess,
    };
    onSubmit && onSubmit(props);
  };
  return (
    <Dialog open={open} fullWidth>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <SettingItem>
          <Typography variant="body1" sx={{ flexShrink: 0 }}>
            ビデオ音量
          </Typography>
          <Slider
            aria-label="VideoVolume"
            value={videoVolume}
            onChange={handleVideoVolumeChange}
            sx={{ maxWidth: '300px' }}
          />
        </SettingItem>
        <SettingItem>
          <Typography variant="body1" sx={{ flexShrink: 0 }}>
            カメラ速度
          </Typography>
          <Slider
            aria-label="CameraSpeed"
            value={cameraSpeed}
            onChange={handleCameraSpeedChange}
            sx={{ maxWidth: '300px' }}
          />
        </SettingItem>
        <SettingItem>
          <Typography variant="body1" sx={{ flexShrink: 0 }}>
            移動速度
          </Typography>
          <Slider
            min={0}
            max={8.0}
            aria-label="MoveSpeed"
            value={moveSpeed}
            onChange={handleMoveSpeedChange}
            sx={{ maxWidth: '300px' }}
          />
        </SettingItem>
        {/* <Typography variant="body1">ポストプロセスをONにする</Typography> */}
        <FormControlLabel
          label="ポストプロセスをONにする"
          control={
            <Checkbox
              checked={enablePostProcess}
              onChange={handleEnablePostProcessChange}
              size="medium"
            />
          }
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleCancel}>
          {closeOnly ? '閉じる' : 'キャンセル'}
        </Button>
        {!closeOnly && (
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            {confirmText ?? '保存する'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default SettingsDialog;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

export type ConfirmationDialogProps = {
  open: boolean;
  title?: string;
  message: string;
  confirmText?: string;
  closeOnly?: boolean;
  onSubmit?: () => void;
  onCancel?: () => void;
};

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  message,
  closeOnly,
  confirmText,
  onSubmit,
  onCancel,
}) => {
  const handleCancel = () => {
    onCancel && onCancel();
  };
  const handleSubmit = () => {
    onSubmit && onSubmit();
  };

  return (
    <Dialog open={open} fullWidth>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleCancel}>
          {closeOnly ? '閉じる' : 'キャンセル'}
        </Button>
        {!closeOnly && (
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            {confirmText ?? 'はい'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmationDialog;

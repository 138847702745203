import React, { useContext } from 'react';

import { Player, Room } from '../types/prisma';

export type AccountContextProps = {
  player?: Player | null;
  setPlayer?: React.Dispatch<Player>;
  refreshPlayer?: () => void;
  room?: Room | null;
  setRoom?: React.Dispatch<Room>;
};

const AccountContext = React.createContext<AccountContextProps>({});

export const useAccount = () => useContext(AccountContext);

export default AccountContext;

import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';

import { Avatar } from '../types/prisma';

export type AvatarCardProps = {
  data: Avatar;
  selected?: boolean;
  onClick?: () => void;
};

const AvatarCard: React.FC<AvatarCardProps> = ({ data, selected, onClick }) => {
  return (
    <Card sx={{ maxWidth: { xs: 72, sm: 120, md: 160 } }}>
      <CardActionArea>
        <CardMedia
          component="img"
          src={data.thumbnailUrl || './logo192.png'}
          alt={data.displayName}
          sx={{ filter: `brightness(${selected ? '1.0' : '0.8'})` }}
          onClick={() => {
            onClick && onClick();
          }}
        />
        {/* 名前表示
        <CardContent>
          <Typography variant="caption">{data.displayName}</Typography>
        </CardContent> */}
      </CardActionArea>
    </Card>
  );
};

export default AvatarCard;

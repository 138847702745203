import { Grid } from '@mui/material';

import { Avatar } from '../types/prisma';
import AvatarCard from './AvatarCard';

export type AvatarListBoxProps = {
  data: Avatar[];
  currentAvatarId?: string | undefined | null;
  onClick?: (avatar: Avatar) => void;
};

const AvatarListBox: React.FC<AvatarListBoxProps> = ({
  data,
  currentAvatarId,
  onClick,
}) => {
  return (
    <Grid container spacing={1} margin="auto">
      {Array.isArray(data) &&
        data.map((v) => (
          <Grid item key={v.id}>
            <AvatarCard
              data={v}
              selected={currentAvatarId === v.id}
              onClick={() => {
                onClick && onClick(v);
              }}
            />
          </Grid>
        ))}
    </Grid>
  );
};

export default AvatarListBox;
